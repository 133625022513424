/* ContactUs.css */
.form-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 500px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

label {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
}

input,
textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus {
    border-color: #28a745;
    box-shadow: 0 0 4px rgba(40, 167, 69, 0.5);
    outline: none;
}

textarea {
    resize: none;
    /* Prevent resizing */
}

.form-item {
    margin-bottom: 20px;
}

.send-button {
    background-color: #28a745;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.send-button:hover {
    background-color: #218838;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.send-button:active {
    background-color: #1e7e34;
}

.hidden {
    display: none;
}