/* Blog Post Styles */

.container {
    padding: 20px;
    padding-top: 80px;
    min-height: calc(100vh - 80px);
    /* Ensure container height fills the viewport */
    font-family: Arial, sans-serif;
}

.head {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.head h1 {
    font-size: 2em;
    margin-bottom: 0;
}

.meta {
    font-size: 0.9em;
    color: #555;
    margin-top: 0;
}

.image {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.content {
    line-height: 1.6;
    font-size: 1em;
    color: #333;
    text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .container {
        padding: 15px;
    }

    .head h1 {
        font-size: 1.5em;
    }

    .meta {
        font-size: 0.8em;
    }

    .image {
        border-radius: 5px;
    }

    .content {
        font-size: 1em;
        line-height: 1.5;
    }
}


/* Skeleton Loader */
.skeleton {
    background: #e0e0e0;
    border-radius: 4px;
    animation: shimmer 1.5s infinite linear;
}

.skeleton-title {
    height: 100px;
    width: 70%;
    margin: 10px 0;
}



.skeleton-meta {
    height: 16px;
    width: 40%;
    margin: 5px 0;
}

.skeleton-image {
    height: 200px;
    width: 100%;
    margin: 20px 0;
    border-radius: 8px;
}

.skeleton-content {
    height: 16px;
    width: 90%;
    margin: 10px 0;
}

/* Shimmer Animation */
@keyframes shimmer {
    0% {
        background-position: -100%;
    }

    100% {
        background-position: 100%;
    }
}

.skeleton {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
}