.not-found-page {
    text-align: center;
    margin: 50px auto;
    font-family: Arial, sans-serif;
  }
  
  .not-found-page h1 {
    font-size: 3em;
    color: #ff4d4d;
  }
  
  .not-found-page p {
    font-size: 1.5em;
    color: #666;
  }
  
  .not-found-page .back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .not-found-page .back-home:hover {
    background-color: #0056b3;
  }