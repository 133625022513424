.login-register-container {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .responsive-image {
    max-width: 100%;
    height: auto;
  }
  
  .form-container {
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
  }
  
  .custom-tabs .ant-tabs-nav {
    display: flex;
  }
  
  .custom-tabs .ant-tabs-tab {
    flex: 1;
    text-align: center;
  }