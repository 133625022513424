.checkbox-wrapper {
    display: flex;
    align-items: center;
    /* Vertically align checkbox and label */
    margin-bottom: 10px;
    /* Add spacing between checkboxes */
}

.checkbox-wrapper .ant-checkbox-wrapper {
    flex: 1;
    /* Ensure the checkbox and text stay in the same row */
    display: flex;
    align-items: center;
}

.service-category h3 {
    margin-top: 20px;
    font-weight: bold;
    color: #333;
}

.section {
    padding: 10px;
    background: #f9f9f9;
    border-radius: 10px;
}