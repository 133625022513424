.home-page {
  display: flex;
  flex-direction: column;
  background-color: rgb(238, 238, 238);
}

.sidebar {
  width: 100%;
  /* Full width to take up space above listings grid */
  z-index: 1000;
  /* Ensure it stays above other content */
}

.listings-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: linear-gradient(180deg, 0%, #1b1f29 100%);
  min-height: 600px;
}

@media (min-width: 769px) {
  .home-page {
    flex-direction: column;
    /* Column direction for larger screens too */
  }

  .sidebar {
    width: 100%;
    /* Full width for consistency */
  }
}

.seo-placeholder {
  min-height: 300px;
  background-color: #f2f2f2;
  /* Light gray for visual indication */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 16px;
}


.home-page-title+.Sidebar {
  margin-top: 20px;
  /* Add space between the title and the Sidebar */
}

.home-page-header {
  text-align: center;
  margin: 20px 20px 20px;
  /* Space between header and next content */
}

.home-page-title {
  font-size: 2rem;
  color: #e74c3c;
  font-weight: bold;
  margin-bottom: 10px;
}

.home-page-description {
  font-size: 1.1rem;
  color: #555;
  font-weight: normal;
}

.home-page-description strong {
  color: #000;
}