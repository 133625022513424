.locations-section {
    margin: 0px;
    padding: 20px;
    /* background-color: #f9f9f9; */
    background-color: rgb(28, 31, 40);
    text-align: center;
    border-top: 1px solid #ddd;
}

.locations-section h2 {
    font-size: 24px;
    color: #DDD;
    margin-bottom: 15px;
}

.locations-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    gap: 10px;
    /* Space between items */
    text-align: left;
}

.locations-list li {
    margin: 0;
}

.location-link {
    color: #CCC;
    text-decoration: none;
    font-size: 16px;
}

.location-link:hover {
    text-decoration: underline;
}