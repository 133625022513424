.App {
  background-color: #fff;
  transition: transform 0.2s;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* Ensure the body takes the full height and uses a flexbox layout for easy vertical stacking */
body,
html {
  height: 100%;
  margin: 0px;
  /* font-family: Arial, sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  overflow-x: hidden;
  line-height: 1.6;
}

/* Wrapper to ensure the content flows below the header */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Adjust the header height variable to match your header's actual height */
:root {
  --header-height: 60px;
  /* Example height, adjust to match your header's height */
}