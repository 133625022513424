.header {
  width: 100%;
  top: 0;
  left: 0;
  height: 40px;
  margin: 0;
  background-color: rgb(28, 31, 40);
  /* background-color: rgb(215, 67, 54); */
  z-index: 1000;
  /* Use a high z-index to ensure it's on top */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
}

.nav {
  display: flex;
  align-items: center;
}

.nav__list {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.auth__list {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 20px;
  padding: 0;
  gap: 30px;
  flex-direction: inherit;
}

@media screen and (max-width: 1000px) {
  .auth__list {
    display: flex;
    list-style: none;
    align-items: center;
    text-align: left;
    margin: 20px 20px 0px 20px;
    padding: 0;
    gap: 20px;
    flex-direction: inherit;
  }

  .nav__item {
    border-bottom: 1px solid #AAA;
    /* Add border around each item */
    padding: 10px;
    /* Add padding for better spacing */
    width: 100%;
  }
}

.nav__menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
}

@media screen and (max-width: 1000px) {
  .nav__menu {
    position: fixed;
    top: -120%;
    left: 0;
    background-color: rgb(242, 244, 246);
    color: rgb(28, 31, 40);
    backdrop-filter: blur(26px);
    -webkit-backdrop-filter: blur(16px);
    width: 100%;
    height: fit-content;
    transform: translateY(-100%);
    display: flex;
    flex-direction: column;
  }

  .nav__link {
    text-decoration: none;
    color: rgb(28, 31, 40);
    ;
  }
}

.show-menu {
  transform: translateY(0);
  top: 60px;

}

.nav__item {
  margin-right: 1rem;
  height: fit-content;
  align-items: center;
}

.nav__item:last-child {
  margin-right: 0;
}

.nav__link {
  text-decoration: none;
  color: rgb(28, 31, 40);
  ;
}


.nav__cta {
  background-color: rgb(255, 255, 255);
  color: rgb(156, 28, 94);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.nav__toggle {
  position: absolute;
  right: 3rem;
  color: white;
  /* Adjust the value to position it as desired */
}

.logo {
  position: absolute;
  color: white;
  width: 30px;
  left: 10px;
}

@media screen and (max-width: 1000px) {
  .nav__logo {
    position: absolute;
    left: 50px;
    font-weight: 700;
    font-size: large;
    /* transform: translateX(-50%); */
    color: white;
    text-decoration: solid;
  }
}