.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px,
}

.location-header Button {
  margin-right: 20px;
  margin-top: 20px;
  width: fit-content;
}

.listings-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background: linear-gradient(180deg, 0%, #1b1f29 100%);
  min-height: 600px;
}