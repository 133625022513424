.services {
  margin: 0px;
  padding: 20px;
  background-color: white;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 14px;
  padding: 8px;
  font-size: 14px;
  width: fit-content;
}

.menu-grid {
  margin: 20px;
  display: flex;
  /* Ensure flex layout for centering */
  justify-content: center;
  background-color: #F4F6F8;

  /* Center horizontally */
}

.desktop-sidebar {
  min-width: 700px;
  max-width: 1000px;
  padding: 15px;
  margin: 20px auto;
  /* Center the sidebar horizontally */
}