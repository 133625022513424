.profile-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: visible;

}

.profile-details {
  flex: 1;
  /* Allows the details container to take up the remaining space */
}

@media (max-width: 768px) {
  .profile-container {
    display: block;
    flex-direction: column;
    /* Stacks items vertically on small screens */
  }

  .call-button {
    position: sticky;
    /* Enables sticky behavior */
    top: 0;
    /* Sticks to the top of the viewport */
    z-index: 1000;
    /* Ensures it stays on top of other elements */
    left: 0;
    width: 100%;
    /* Full width */
    height: 60px;
    /* Adjust height as needed */
    background-color: #E6E8EA;
    /* Light gray background */
    color: #28a745;
    /* Green text color */
    font-size: 1.5rem;
    /* Adjust font size as needed */
    font-weight: bold;
    /* Bold font for better visibility */
    border: none;
    /* Removes border */
    cursor: pointer;
    /* Pointer cursor */
    text-align: center;
    /* Center-align text */
    display: flex;
    /* Flexbox for alignment */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    gap: 10px;
    /* Space between icon and text */
    padding: 0 10px;
    /* Prevent overflow on smaller screens */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Add subtle shadow for elevation */
  }

  .call-button:hover {
    background-color: #dcdfe1;
    /* Slightly darker background on hover */
    color: #218838;
    /* Darker green for text on hover */
  }

  .outer-div {
    position: relative;
    width: 100%;
    /* Adjust as needed */
    overflow: hidden;
    background: #000;
    /* Fallback background */
    background-repeat: no-repeat;
  }

  .foreground-image {
    position: relative;
    width: 100%;
    /* height: 350px; */
    overflow: hidden;
    filter: none;
    /* Prevent blur on the image */
    object-fit: cover;
  }

  /* Blurred background */
  .blur-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    filter: blur(20px);
    transform: scale(1.2);
    /* Scale slightly to ensure coverage */
  }

  .blur-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(20px);
    /* Additional blur for the background */
    position: absolute;
  }

  /* Main image */
  .main-image {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* Ensures the entire image is visible */
    z-index: 2;
    /* Stays above the blurred background */
  }

  .call-button {
    position: sticky;
    /* Enables sticky behavior */
    top: 0;
    /* Sticks to the top of the viewport */
    z-index: 1000;
    /* Ensures it stays on top of other elements */
    left: 0;
    width: 100%;
    /* Full width */
    height: 60px;
    /* Adjust height as needed */
    background-color: #E6E8EA;
    color: #28a745;
    /* color: #cb2845; */
    font-size: 1.7rem;
    font-weight: bold;
    /* Adjust font size as needed */
    border: none;
    cursor: pointer;
    text-align: center;
  }

  .call-button:focus {
    outline: none;
    /* Remove focus outline */
  }

  .about-container {
    text-align: left;
    padding: 10px 10px 10px 20px;
    margin: 0px;
  }

  .sections-heading {
    margin: 0;
    /* Ensures h2 inherits spacing rules */
    color: #cb2845;
  }

  .about-paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: #212a33;
    white-space: pre-wrap;
    word-break: break-word;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .services {
    margin: 16px;
    padding: 20px;
    background-color: white;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }

  .service-tag {
    background-color: #F8FAFC;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.6;
  }

  .similar-profiles {
    display: flex;
    /* Use flexbox to align items horizontally */
    flex-wrap: nowrap;
    /* Prevent wrapping to ensure horizontal scrolling */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    gap: 16px;
    /* Add spacing between cards */
    padding: 16px;
    background-color: rgb(28, 31, 40);
  }

  .similar-profiles::-webkit-scrollbar {
    height: 4px;
    /* Set the height of the scrollbar */
  }

  .similar-profiles::-webkit-scrollbar-thumb {
    background: rgb(150, 150, 150);
    /* Set scrollbar color */
  }

  .similar-profiles::-webkit-scrollbar-track {
    background: #f0f0f0;
    /* Set scrollbar track color */
  }

  .locations-section {
    margin: 30px 0;
    padding: 20px;
    background-color: #f9f9f9;
    text-align: center;
    border-top: 1px solid #ddd;
  }

  .locations-section h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
  }

  .locations-list {
    list-style-type: none;
    /* Removes bullet points */
    padding: 0;
    /* Removes default padding */
    margin: 0;
    /* Removes default margin */
    display: grid;
    /* Use Grid layout */
    grid-template-columns: repeat(2, 1fr);
    /* Create 2 equal columns */
    gap: 10px;
    /* Space between items */
    text-align: left;
    /* Align text to the left */
  }

  .locations-list li {
    margin: 0;
    /* No additional spacing needed */
  }

  .location-link {
    color: #cb2845;
    text-decoration: none;
    font-size: 16px;
  }

  .location-link:hover {
    text-decoration: underline;
  }
}