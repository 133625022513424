/* ListingCard.css */

.card-link {
  text-decoration: none;
  width: 100%;
  max-width: 350px;
  margin-bottom: 20px;
}

.card-image-container {
  overflow: hidden;
  width: 100%;
}

.card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-action {
  align-items: center;
  text-align: center;
  color: rgb(71, 137, 51);
  font-size: 18px;
  cursor: pointer; /* Adds a pointer cursor on hover */
}

.card-title {
  font-size: 22px;
  color: rgb(0, 100, 180);
}

.card-description {
  font-size: 14px;
}